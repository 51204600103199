<script>
  export default {
    props: {
      secondsLeft: {
        type: Number,
        required: true,
      },
    },
    computed: {
      isCountdownValid() {
        return !isNaN(this.secondsLeft)
      },
      isCountdownDone() {
        return this.secondsLeft < 0
      },
      minutes() {
        return Math.floor(this.secondsLeft / 60) % 60
      },
      seconds() {
        return this.secondsLeft % 60
      },
      formattedTime() {
        const minutes = this.formatTimeComponent(this.minutes)
        const seconds = this.formatTimeComponent(this.seconds)
        return this.isCountdownDone ? '00 : 00' : `${minutes} : ${seconds}`
      },
    },
    methods: {
      formatTimeComponent(value) {
        return String(value).padStart(2, '0')
      },
    },
  }
</script>

<template>
  <div
    v-if="isCountdownValid"
    class="w-42 bg-dawn-lt4 rounded-full text-primary flex flex-col items-center mx-auto"
    style="box-shadow: 0 0 16.5px 0 rgba(96, 90, 76, 0.24)"
  >
    <div class="font-semibold text-3xs mt-2 -mb-2"> Time left to join </div>
    <div class="font-heading font-bold text-40px leading-snug">{{ formattedTime }}</div>
  </div>
</template>
